$system-color-gray: (
  "gray": (
    1: #fcfcfc,
    2: #f9f9f9,
    3: #f6f6f6,
    4: #f3f3f3,
    5: #f0f0f0,
    10: #e6e6e6,
    20: #c9c9c9,
    30: #adadad,
    40: #919191,
    50: #757575,
    60: #5c5c5c,
    70: #454545,
    80: #2e2e2e,
    90: #1b1b1b,
    100: #000000
  )
);
