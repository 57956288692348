$system-color-gray-cool: (
  "gray-cool": (
    1: #fbfcfd,
    2: #f7f9fa,
    3: #f5f6f7,
    4: #f1f3f6,
    5: #edeff0,
    10: #dcdee0,
    20: #c6cace,
    30: #a9aeb1,
    40: #8d9297,
    50: #71767a,
    60: #565c65,
    70: #3d4551,
    80: #2d2e2f,
    90: #1c1d1f
  )
);
