$system-color-cyan: (
  "cyan": (
    5: #e7f6f8,
    10: #ccecf2,
    20: #9ddfeb,
    30: #6ecbdb,
    40: #449dac,
    50: #168092,
    60: #2a646d,
    70: #2c4a4e,
    80: #203133,
    90: #111819,
    "vivid": (
      5: false,
      10: #a8f2ff,
      20: #52daf2,
      30: #00bde3,
      40: #009ec1,
      50: false,
      60: false,
      70: false,
      80: false,
      90: false
    )
  )
);
